import { render, staticRenderFns } from "./HanbookMatching.vue?vue&type=template&id=213edb70&scoped=true&"
import script from "./HanbookMatching.vue?vue&type=script&lang=js&"
export * from "./HanbookMatching.vue?vue&type=script&lang=js&"
import style0 from "./HanbookMatching.vue?vue&type=style&index=0&id=213edb70&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "213edb70",
  null
  
)

export default component.exports