<template>
    <div class="match-file-attaching">
        <div>
            <p>Обязательный порядок столбцов: <strong>Город, Адрес, Оператор, Gid, Вид, Тип, Формат, Сторона, Широта, Долгота</strong></p>
            <p>Если какого то нет, оставляйте столбец пустым</p>
        </div>
        <attach-file
            class="match-file-attaching__attach"
            :is-match-file="true"
            :file-formats="formatFile"
            :attach-title="title"
            @change="onAttach" />
    </div>
</template>

<script>
/** Компонент шага мэтчинга: прикрепление файла */
import AttachFile from "@/components/AttachFile";

export default {
    name: 'MatchFileAttaching',
    components: {
        AttachFile,
    },
    data: () => ({
        formatFile: ['.xls', '.xlsx'],
        title: 'Перетащите сюда файл или выберите на компьютере до 10 mb',
        matchType: "matcher"
    }),
    methods: {
        /**
         * Событие прикрепления файла
         * @param {Object} file - объект файла
         */
        onAttach(file = null) {
            this.$emit('attach', file, this.matchType);
        }
    }
}
</script>

<style lang="scss" scoped>
    .match-type{
        padding-right: 5px;
    }
    .match-file-attaching {
        &__attach {
            max-height: 466px;
        }
    }
</style>
