<template>
	<div class="condition">
        <ConditionItem
            v-for="(item, index) in items"
            :key="index"
            :id="item.id"
            :updates="item.updates"
            :conditions="item.conditions"
            :sideCount="item.count"
            :selectItems="selectItems"
            @onSaveItem="onSaveItem"
            @onDeleteItem="onDeleteItem"
            @onChangeSelect="onChangeSelect"
            @onEditInput="onEditInput"
            @onResetItem="onResetItem"
        />
	</div>
</template>

<script>
// import BaseButton from "@/components/Base/BaseButton";
import ServiceCondition from "../services/ServicesCondition/ServiceCondition";
import ConditionItem from "../components/Condition/ConditionItem";
/* eslint-disable no-mixed-spaces-and-tabs */
export default {
	name: "ConditionPage",
	components: {
        ConditionItem
        // HandbookEntity,
	},
	data() {
		return {
		    items: [],
            defaultItems: [],
            selectItems: [],
            emptyItem:{
                id: 0,
                conditions: [],
                updates: [],
                count: "0"
            },
            // currentEntity: 'marker',
            // usersData: [],
		}
	},
	computed: {

	},
    beforeMount() {
	    this.getSelectItems()
        this.getConditions()
    },
	methods: {
        getConditions(){
            ServiceCondition.getList((items) => {
                let bufferItems = [...items];
                bufferItems.push({...this.emptyItem})
                this.defaultItems = Object.assign([], bufferItems)
                this.items = Object.assign([], bufferItems)
            })
        },
        getSelectItems(){
            ServiceCondition.getElements((items) => {
                this.selectItems = items
            })
        },
        onSaveItem(id){
            if(!confirm("Уверены что хотите сохранить?")){
                return
            }
            console.log("Сохранить: " + id)
            let items = [...this.items];
            let itemIndex = items.findIndex((item) => item.id === id);
            let targetItem = {...items[itemIndex]};
            if(id > 0){
                this.updateCondition(id, targetItem, itemIndex)
            }else{
                this.createCondition(targetItem, itemIndex)
            }
        },
        onDeleteItem(id){
            if(!confirm("Уверены что хотите удалить?")){
                return
            }
            console.log("Удалить: " + id)
            let items = [...this.items];
            let itemIndex = items.findIndex((item) => item.id === id);

            ServiceCondition.delete(id, () => {
                let items = [...this.items];
                let defaultItems = [...this.defaultItems];
                items.splice(itemIndex, 1)
                defaultItems.splice(itemIndex, 1)
                this.items = Object.assign([], items);
                this.defaultItems = Object.assign([], defaultItems);
            })
        },
        createCondition(targetItem, itemIndex) {
            ServiceCondition.create(targetItem, (item) => {
                let items = [...this.items];
                let defaultItems = [...this.defaultItems];
                items.splice(itemIndex, 1, item)
                defaultItems.splice(itemIndex, 1, item)
                items.push({...this.emptyItem})
                defaultItems.push({...this.emptyItem})
                this.items = Object.assign([], items);
                this.defaultItems = Object.assign([], defaultItems);
                alert("Сохранено")
            })
        },
        updateCondition(id, targetItem, itemIndex) {
            ServiceCondition.update(id, targetItem, (item) => {
                let items = [...this.items];
                let defaultItems = [...this.defaultItems];
                items.splice(itemIndex, 1, item)
                defaultItems.splice(itemIndex, 1, item)
                this.items = Object.assign([], items);
                this.defaultItems = Object.assign([], defaultItems);
                alert("Сохранено")
            })
        },
        onResetItem(id){
            console.log("Сбросить: " + id)
            let items = [...this.items];

            let itemIndex = this.defaultItems.findIndex((item) => item.id === id);
            let defaultItem = {...this.defaultItems[itemIndex]};
            items.splice(itemIndex, 1, defaultItem)
            this.items = Object.assign([], items);
        },
        onChangeSelect(data){
            let {attributeNew,/* attributeOld,*/ id, type, index: itemElementIndex} = data;
            let items = [...this.items];

            let itemIndex = items.findIndex((item) => item.id === id);
            let targetItem = {...items[itemIndex]};
            let targetItemElements = [...targetItem[type]]

            if(itemElementIndex === -1){
                targetItemElements.push({attribute: attributeNew, value: null})
            }else if(attributeNew === null){
                targetItemElements.splice(itemElementIndex, 1);
            }else{
                targetItemElements.splice(itemElementIndex, 1, {attribute: attributeNew, value: null});
            }

            targetItem[type] = [...targetItemElements]
            if(type === 'conditions'){
                this.updateItemCount(items, targetItem, itemIndex);
            }else{
                items.splice(itemIndex, 1, targetItem);
                this.items = Object.assign([], items);
            }
        },
        onEditInput(data){
            // console.log(data)
            let items = [...this.items];
            let {id, /*attribute, */type, value, index: itemElementIndex} = data;

            let itemIndex = items.findIndex((item) => item.id === id);
            let targetItem = {...items[itemIndex]};
            let targetItemElements = [...targetItem[type]]

            // let itemElementIndex = targetItemElements.findIndex(item => item.attribute === attribute)
            let itemTargetElement = {...targetItemElements[itemElementIndex], value: value}
            targetItemElements.splice(itemElementIndex, 1, itemTargetElement);
            targetItem[type] = [...targetItemElements]
            if(type === 'conditions'){
                this.updateItemCount(items, targetItem, itemIndex);
            }else{
                items.splice(itemIndex, 1, targetItem);
                this.items = Object.assign([], items);
            }
        },
        updateItemCount(items, targetItem, itemIndex){
            ServiceCondition.calculate(targetItem, (newCount) => {
                targetItem.count = newCount;
                items.splice(itemIndex, 1, targetItem);
                this.items = Object.assign([], items);
            }, () => {
                items.splice(itemIndex, 1, targetItem);
                this.items = Object.assign([], items);
            });
        }
	}
}
</script>

<style lang="scss" scoped>
.condition {
    display: flex;
    flex-wrap: wrap; /* Позволяет элементам переноситься на новую строку */
    gap: 10px; /* Устанавливает расстояние между ячейками */
    overflow-y: auto;
    height: 100%;
}
</style>
