<template>
	<BaseDropdown
        :isFixed="isFixed"
        :isVirtualScroll="isVirtualScroll"
        :spacerHeight="containerHeight + 50"
        :position="position"
		class="select"
		v-bind="$attrs"
		@changeVisible="onDropdown"
	>
		<template #preview>
			<BaseButton
				:active="localOpen"
                :style="{background: previewColor}"
				class="select-preview"
			>
                <div
                    v-if="isCustomPreview"
                    class="select-preview__circle"
                >
                    <base-icon
                        icon="icon-tick"
                        size="sm"
                        color="#fff"
                        class="select-preview__circle-icon"
                    />
                </div>
				<span class="select-preview__label" :title="title">
					{{title}}
				</span>

				<div class="select-preview__arrow">
					<img src="@/assets/images/icons/arrow.svg">
				</div>
			</BaseButton>
		</template>

		<template #content>
			<div class="select-dropdown">
				<div class="select-dropdown__triangle" />
				<!-- HEADER -->
				<div v-if="$slots.header" class="select-dropdown__header">
					<div class="select-dropdown__top">
						<slot name="header" />
					</div>
				</div>

				<!-- SEARCH -->
				<div
					v-if="canSearch"
					class="select-dropdown__search"
				>
					<input
						v-model="searchValue"
						type="text"
						placeholder="Поиск"
					>
				</div>

				<!-- OPTIONS -->
				<div
                    ref="selectOptionsContainer"
                    class="select-options"
                    @scroll="onScroll"
                >
                    <div ref="spacer" :style="getSpacerStyle()">
                        <div
                            v-for="(option, index) of virtualOptions"
                            :key="index"
                            class="select-options__item"
                            :class="getOptionClasses(option)"
                            :style="getOptionStyle()"
                            :title="isVirtualScroll ? option.value : null"
                            @click="onCheck(option)"
                        >
                            <span>{{option.value}}</span>
                            <div
                                v-if="getIsRequired(option)"
                                class="select-options__item_required"
                            >
                                *
                            </div>
                        </div>
                    </div>
				</div>

				<!-- FOOTER -->
				<div
					v-if="$slots.footer"
					class="select-dropdown__footer"
				>
					<slot name="footer" />
				</div>
			</div>
		</template>
	</BaseDropdown>
</template>

<script>
import BaseButton from "./BaseButton"
import BaseDropdown from "./BaseDropdown"
import BaseIcon from "@/components/Base/BaseIcon";
export default {
	name: "BaseSelect",
	components: {
		BaseButton,
		BaseDropdown,
        BaseIcon
	},
	props: {
		/**
		 * @property {Array} options - массив опций
		 * @property {Array} value - выбранное значение по умолчанию
		 */
		options: {
			type: Array,
			default: () => []
		},
		value: {
			type: [String, Number, Array],
			default: null
		},
		defaultTitle: {
			type: [String, Number],
			default: 'Выбрать значение',
		},
        position:{
            type: String,
            default: 'right',
            validator: (value) => ['right', 'left'].includes(value)
        },
		canSearch: {
			type: Boolean,
			default: false
		},
		isCloseAfterCheck: {
			type: Boolean,
			default: true
		},
		open: {
			type: Boolean,
			default: false
		},
        isCustomPreview: {
            type: Boolean,
            default: false
        },
        previewColor: {
            type: String,
            default: ''
        },
        isFixed: {
            type: Boolean,
            default: false
        },
        isVirtualScroll: {
            type: Boolean,
            default: false
        },
        virtualScrollConfig: {
            type: Object,
            default: () => ({})
        }
	},
	data() {
		/**
		 * @property {Boolean} localOpen - условие отображение опций
		 */
		return {
			searchValue: '',
			localOpen: this.open,
            scrollTop: 0,
            nodeCountVisible: 0,
            spacerHeight: 0
		}
	},
	computed: {
		/**
		 * Вернет title для селекта
		 */
		title() {
			const currentOption = this.options.find(i => i.id === this.value) || {}
			return currentOption.value || this.defaultTitle
		},
		/**
		 * Массив значений по ключу поиска
		 */
		filteredOptions() {
            const filteredOptions = this.options.filter(item => {
				const optionValue = String(item.value).toLowerCase()
				const searchValue = String(this.searchValue).toLowerCase()

				return optionValue.startsWith(searchValue)
			})
			return filteredOptions;
		},
        virtualOptions() {
            return this.isVirtualScroll ? this.filteredOptions.slice(this.nodeIndexStart, this.nodeIndexEnd + 1) : this.filteredOptions;
        },
        optionsRequired() {
            const optionsRequired = this.options.filter(option => {
                const {required = false, requiredGroup = '', requiredType = ''} = option;
                return Boolean(required) || String(requiredGroup) !== '' || String(requiredType) !== '';
            }).map(option => {
                const {id = ''} = option;
                return String(id);
            });
            return optionsRequired;
        },
        nodeHeight() {
            const {nodeHeight = 35} = this.virtualScrollConfig;
            return nodeHeight;
        },
        nodeIndexStart() {
            const nodeIndexStart = Math.floor(this.scrollTop / this.nodeHeight) - 5;
            return Math.max(0, nodeIndexStart);
        },
        nodeIndexEnd() {
            const nodeIndexEnd = this.nodeIndexStart + 12;
            return Math.min(nodeIndexEnd, (this.filteredOptions.length - 1));
        },
        containerHeight() {
            return this.filteredOptions.length * this.nodeHeight;
        },
        spacerTopStyle() {
            const spacerTopStyle = `${this.nodeIndexStart * this.nodeHeight}px`;
            return spacerTopStyle;
        },
        spacerBottomStyle() {
            const spacerBottomStyle = `${((this.filteredOptions.length - 1) - this.nodeIndexEnd) * this.nodeHeight}px`;
            return spacerBottomStyle;
        }
	},
    watch: {
        localOpen(isLocalOpen) {
            if (this.isVirtualScroll) {
                if (isLocalOpen)
                    this.$nextTick(() => {
                        this.getNodeCountVisible();
                        this.getSpacerHeight();
                    });
                else
                    this.scrollTop = 0;
            }
        },
        filteredOptions() {
            if (this.localOpen && this.isVirtualScroll) {
                this.$refs.selectOptionsContainer.scrollTop = 0;
                this.scrollTop = 0;
            }
        }
    },
	created() {
		// Сработает при эмите события cancel в родителе
		this.$parent.$on('$closeSelect', this.onClose)
	},
	methods: {
        onScroll(event) {
            if (this.isVirtualScroll) {
                const {currentTarget = {}} = event;
                const {scrollTop = 0} = currentTarget;
                this.scrollTop = scrollTop;
            }
        },
        getIsRequired(option) {
            const {id = ''} = option;
            return this.optionsRequired.includes(String(id));
        },
		updateSearchValues(value){
			this.searchValue = value;
		},
        getNodeCountVisible() {
            const {selectOptionsContainer = {}} = this.$refs;
            const bounding = selectOptionsContainer.getBoundingClientRect() || {};
            const{height = 0} = bounding;
            const nodeCountVisible = Math.floor(height / this.nodeHeight);
            this.nodeCountVisible = nodeCountVisible;
        },
        getSpacerHeight() {
            this.spacerHeight = this.containerHeight;
        },
        getOptionStyle() {
            const optionStyle = {
                'white-space': 'nowrap',
                'height': `${this.nodeHeight}px`,
            };
            return this.isVirtualScroll ? optionStyle : {};
        },
        getSpacerStyle() {
            const spacerStyle = {
                'padding-top': this.spacerTopStyle,
                'padding-bottom': this.spacerBottomStyle
            };
            return this.isVirtualScroll ? spacerStyle : {};
        },
		/**
		 * @returns {Object} классы для опций
		 */
		getOptionClasses(option) {
			if (Array.isArray(this.value)) {
				return {
					'select-options__item_active': this.value.includes(option.id)
				}
			}

			return {
				'select-options__item_active': this.value === option.id
			}
		},
		/**
		 * close
		 */
		onClose() {
			this.$emit('$closeDropdown')
			this.$emit('close')
		},
		/**
		 * @param {Object} option - option по которому был совершен клик
		 */
		onCheck(option) {
			this.$emit('check', option)

			if (this.isCloseAfterCheck) {
				this.onClose()
			}
		},
		onDropdown(event) {
			this.localOpen = event
		}
	}
}
</script>

<style lang="scss" scoped>
$primary-color: #F0F3F8 !default;
$success-color: #6EC87A !default;
$primary-disabled-color: #DCE1E8 !default;

.select {
	display: inline-block;
	position: relative;
	width: 130px;
	user-select: none;
	outline: none;
	font-size: 13px;

	&-preview {
		padding: 6px 15px;
		border: 1px solid transparent;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		transition: .2s;
		text-align: left;
        &__circle {
            border-radius: 100%;
            width: 14px;
            height: 14px;
            background: #6EC87A;
            margin-right: 5px;
            &-icon {
                padding-left: 2px;
            }
        }

		&__label {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			flex-grow: 1;
		}

		&__arrow {
			transform: rotate(-90deg);
			width: 7px;
			margin-left: 10px;
			flex-shrink: 0;

			img { width: 100%; }
		}
	}

	&-dropdown {
        height: 100%;
		max-height: 350px;
		overflow: hidden;
		display: flex;
		flex-direction: column;

		&__search {
			width: 100%;
			cursor: pointer;
			border-radius: inherit;
			transition: .1s;

			input {
				border: none;
				border-bottom: 1px solid $primary-disabled-color;
				background: transparent;
				height: 34px;
				width: 100%;
				display: block;
				outline: none;
				padding-left: 16px;

				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpath id='iconmonstr-magnifier-2' d='M10,9.019,7.394,6.434a4.012,4.012,0,0,0,.78-2.38A4.087,4.087,0,0,0,0,4.054,4.092,4.092,0,0,0,6.391,7.4L9.011,10,10,9.019ZM1.2,4.054A2.888,2.888,0,1,1,4.087,6.92,2.88,2.88,0,0,1,1.2,4.054Z' fill='%23b9bbc2'/%3E%3C/svg%3E");
				background-repeat: no-repeat;
				background-position: center left;
				background-size: 10px;
				font-size: 12px;

				&::placeholder {
					color: $primary-disabled-color !important;
				}
			}
		}

		&__header {
			flex-shrink: 0;
			flex-grow: 0;
		}
		&__top {
			margin-bottom: 4px;
		}
		&__footer {
			flex-shrink: 0;
			flex-grow: 0;
			margin-top: 20px;
		}
	}

	&-options {
		flex-grow: 1;
		overflow: auto;
		margin-top: 10px;
		padding-right: 10px;

		&__item {
			border: 1px solid transparent;
			max-width: 100%;
			cursor: pointer;
			border-radius: 400px;
			transition: .1s;
			padding: 8px 15px;
            display: flex;
            &_required {
                color: #F84967;
            }
			&:hover:not(.select-options__item_active) {
				background: $primary-color;
			}

			span {
				// white-space: nowrap;
				display: block;
				width: 90%;
				text-overflow: ellipsis;
				// white-space: nowrap;
				overflow: hidden;
			}

			&_active {
				border: 1px solid $success-color;
				color: $success-color;
			}
		}
	}
}
</style>
