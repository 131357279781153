import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Handbook from "../pages/handbook"
import Condition from "../pages/condition"
import LayoutAuth from "../layouts/LayoutAuth";
import ServiceTokenObserver from "../services/ServicesAuth/ServiceTokenObserver";
const router = new VueRouter({
	routes: [
        // {
        //     path: '/login',
        //     name: 'login',
        //     meta: { title: 'Войти', layout: ''},
        //     component: LayoutLogin
        // },
        {
            path: '/auth',
            name: 'auth',
            meta: { title: 'Аутентификация', layout: ''},
            component: LayoutAuth
        },
		{
			path: '/',
			meta: { title: 'Справочник' },
			// component: Home,
            redirect: '/handbook'
		},
        {
            path: '/login',
            name: 'login',
            meta: { title: 'Вход', layout: 'LayoutLoginV2' },
            component: () => import('../pages/PageLoginV2'),
        },
		{
			name: 'handbook',
			path: '/handbook',
			meta: { title: 'Справочник', auth: true},
			component: Handbook
		},
        {
            name: 'condition',
            path: '/condition',
            meta: { title: 'Условия массовой замены', auth: true},
            component: Condition
        },
        // {
        //     name: 'PageHelp',
        //     path: '/help',
        //     meta: { title: 'Помощь'},
        //     component: PageHelp
        // },
        // {
        //     path: '/buyerdesk',
        //     meta: {title: 'Подбор предложений', layout: 'WithFilterLayout', auth: true},
        //     component: PageBayuerdeskV2,
        //     name: 'bayuerdeskV2',
        //     props: (route) => ({ isShare: typeof(route.query.shareToken) !== 'undefined' && route.query.shareToken !== null})
        // },
        // {   /* TODO */
        //     path: '/price',
        //     name: 'price',
        //     meta: {title: 'Формирование бюджета', layout: 'WithoutFilterLayout', auth: true},
        //     component: () => import('../pages/PricePage'),
        //     props: (route) => ({ selectedSetId: route.query.setId})
        // },
        // {   /* TODO */
        //     path: '/booking',
        //     name: 'booking',
        //     meta: {title: 'Онлайн-бронирование конструкций', layout: 'WithoutFilterLayout', auth: true},
        //     component: () => import('../pages/PageBooking'),
        //     props: (route) => ({ selectedSetId: route.query.setId})
        // },
        // {
        //     path: '/reconciliation',
        //     name: 'reconciliationTemplate',
        //     meta: {title: 'Создание шаблона для файлов сверок'},
        //     component: () => import('../pages/PageReconciliation'),
        //     props: (route) => ({ presetFileId: route.query.file , presetId: route.query.preset})
        // },
        // {
        //     path: '/reconciliation-start',
        //     name: 'reconciliationStart',
        //     meta: {title: 'Шаблоны для файлов сверок'},
        //     component: () => import('../pages/PageReconciliationStart'),
        // },
        // {
        //     path: '/reconciliation-history',
        //     name: 'reconciliationHistory',
        //     meta: {title: 'История импорта'},
        //     component: () => import('../pages/PageReconciliationHistory'),
        // },
        // {
        //     path: '/reconciliation-download',
        //     name: 'reconciliationDownload',
        //     meta: {title: 'Загрузка сверок'},
        //     // props: true,
        //     component: () => import('../layouts/LayoutReconciliationDownload'),
        //     props: (route) => ({ presetFileId: route.query.file })
        // },
        // {
        //     path: '/reconciliation-edit',
        //     name: 'reconciliationEdit',
        //     meta: {title: 'Сверки', layout: 'WithFilterMiniLayout'},
        //     component: () => import('../pages/PageReconciliationEdit')
        // },
        // {
        //     path: '/reconciliation-budget',
        //     name: 'reconciliationBudget',
        //     meta: {title: 'Сводные данные', layout: 'WithFilterMiniLayout'},
        //     component: () => import('../pages/PageReconciliationBudget')
        // },
        /*,
        {
            path: '/bayuerdesk-v2',
            meta: {title: 'Подбор предложений', layout: 'WithFilterLayout'},
            component: () => import('../pages/PageBayuerdeskV2'),
            name: 'bayuerdeskV2'
        }*/
	],
	mode: 'history',
	scrollBehavior: () => ({ x: 0, y: 0 })
});

router.beforeEach((to, from, next) => {
    Vue.nextTick( () => {
        document.title = to.meta.title ? to.meta.title : 'oohdesk';
    });
    const {meta = {}, name = ''} = to;
    const {auth = false} = meta;
    if (window.serviceTokenObserver !== null && typeof(window.serviceTokenObserver) !== 'undefined')
        window.serviceTokenObserver.clearTokenListener();
    window.serviceTokenObserver = null;
    window.serviceShare = null;
    if (name === 'login') {
        window.serviceTokenObserver = new ServiceTokenObserver(false);
        if (!window.serviceTokenObserver.isLogin)
            next({name: 'handbook'});
        else
            next();
    }
    if (name === 'auth') {
        if (window.serviceTokenObserver === null || typeof(window.serviceTokenObserver) === 'undefined')
            window.serviceTokenObserver = new ServiceTokenObserver(false);
        if (!window.serviceTokenObserver.isAuth)
            next({name: 'handbook'});
        else
            next();
    }
    if (auth) {
        window.serviceTokenObserver = new ServiceTokenObserver(true);
        if(window.serviceTokenObserver.isLogin)
            next({name: 'login'});
        else
            next();
    }
    else {
        next();
    }
});

export default router;
