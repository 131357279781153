<template>
    <div class="layout">
        <!-- <AppSidebar
            class="layout__sidebar"
            :isHide="isHideSidebar"
            :sidebarItems="sidebarItems"
            @onHide="onHideSidebar"
        /> -->
        <div class="layout__main">
            <Cap
                class="layout__cap"
                :title="$route.meta.title || 'Oohdesk'"
                :logo-src="capLogoSrc"
                view="transparent"
                :config="capConfig"
                :user-menu-data="profile"
                :isShowAppMenu="isMenuOpen"
                @onHamburger="onHamburger"
                @onUserMenuLogout="logout"
            ></Cap>
            <div class="layout__content">
                <AppMenu
                    v-show="isMenuOpen"
                    class="layout__menu"
                />

                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
// import AppSidebar from './components/Sidebar'
import AppMenu from './components/Menu'
import ServiceUser from "@/services/ServicesAuth/ServiceUser";
import ServiceAuth from "@/services/ServicesAuth/ServiceAuth";

export default {
    name: 'DefaultLayout',
    components: {
        // AppSidebar,
        AppMenu,
    },
    data() {
        return {
            isHideSidebar: true,
            isMenuOpen: false,
            profile: {},
            sidebarItems: [
                {
                    label: 'Загрузка сверок',
                    icon: 'icon-document-2',
                    path: '/reconciliation-start'
                },
                {
                    label: 'Сверка факт',
                    icon: 'icon-document-2',
                    path: '/reconciliation-edit'
                },
                {
                    label: 'Сводные данные',
                    icon: 'icon-document-2',
                    path: '/reconciliation-budget'
                },
            ]
        }
    },
    mounted() {
        this.profile = ServiceUser.profile;
    },
    computed: {
        capConfig() {
            return {
                isConfigHamburger: true,
                isConfigStatus: false,
                isConfigNotification: false,
                isConfigSettings: false,
            };
        },
        capLogoSrc() {
            return require('@/assets/images/logo-sv-min.svg');
        }
    },
    methods: {
        onHideSidebar(event) {
            this.isHideSidebar = event
        },
        onHamburger() {
            this.isMenuOpen = !this.isMenuOpen
        },
        async logout() {
            await ServiceAuth.logout();
        },
    }
}
</script>

<style lang="scss" scoped>
.layout {
    height: 100vh;
    display: flex;

    &__cap {
        flex-grow: 0;
        flex-shrink: 0;
    }

    &__main {
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    &__sidebar {
        flex-grow: 0;
        flex-shrink: 0;
    }

    &__content {
        position: relative;
        width: 100%;
        height: 100%;
        background: #fff;
        overflow: hidden;
        padding: 20px 40px 20px 40px;
    }

    &__menu {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background: #fff;
        z-index: 2000;
    }
}
</style>
