<template>
	<div
        class="condition-item"
    >
        <div class="condition-item__panel">
            <div class="condition-item__panel-title">
                Id: {{id}} ({{sideCount}} ст)
            </div>
            <div class="condition-item__panel-buttons">
                <button @click="onResetItem" title="Откатить" class="condition-item__panel-button">
                    <span class="icon-arrow-circle-prev"></span>
                </button>
                <button @click="onSaveItem" title="Сохранить" class="condition-item__panel-button">
                    <span class="icon-edit"></span>
                </button>
                <button v-if="id > 0"  @click="onDeleteItem" title="Удалить" class="condition-item__panel-button">
                    <span class="icon-delete"></span>
                </button>
            </div>
        </div>
        <div class="condition-item__container">
            <div class="condition-item-block">
                <h3>Условия</h3>
                <ConditionItemElement
                    v-for="(condition, index) in conditions"
                    :key="index"
                    :type="'conditions'"
                    :attribute="condition.attribute"
                    :value="condition.value"
                    :selectItems="selectItems"
                    @onChangeSelect="onChangeSelect(index, $event)"
                    @onEditInput="onEditInput(index, $event)"
                />
                <ConditionItemElement
                    :attribute="null"
                    :type="'conditions'"
                    :value="null"
                    :selectItems="selectItems"
                    @onChangeSelect="onChangeSelect(-1, $event)"
                    @onEditInput="onEditInput(-1, $event)"
                />
            </div>
            <div  class="condition-item-block" v-if="conditions.length">
                <h3>Замены</h3>
                <ConditionItemElement
                    v-for="(update, index) in updates"
                    :key="index"
                    :type="'updates'"
                    :attribute="update.attribute"
                    :value="update.value"
                    :selectItems="selectItems"
                    @onChangeSelect="onChangeSelect(index, $event)"
                    @onEditInput="onEditInput(index, $event)"
                />
                <ConditionItemElement
                    :attribute="null"
                    :type="'updates'"
                    :value="null"
                    :selectItems="selectItems"
                    @onChangeSelect="onChangeSelect(-1, $event)"
                    @onEditInput="onEditInput(-1, $event)"
                />
            </div>
        </div>
	</div>
</template>

<script>
import ConditionItemElement from "./ConditionItemElement";

export default {
    name: "ConditionItem",
    components: {
        ConditionItemElement
    },

    props: {
        id: {
            type: Number,
            default: null
        },
        sideCount: {
            type: String,
            default: null
        },
        updates: {
            type: Array,
            default: () => ([])
        },
        conditions: {
            type: Array,
            default: () => ([])
        },
        selectItems: {
            type: Array,
            default: () => ([])
        }
    },

    data: () => ({

    }),
    watch: {

    },
    mounted() {

    },
    methods: {
        onSaveItem(){
            this.$emit("onSaveItem", this.id);
        },
        onResetItem(){
            this.$emit("onResetItem", this.id);
        },
        onDeleteItem(){
            this.$emit("onDeleteItem", this.id);
        },
        onChangeSelect(index, data){
            // console.log(index, data)
            this.$emit("onChangeSelect", {...data, id: this.id, index});
        },
        onEditInput(index, data){
            // console.log(index, data)
            this.$emit("onEditInput", {...data, id: this.id, index});
        }
    }
}
</script>

<style lang="scss" scoped>

.condition-item {

    box-sizing: border-box; /* Убедитесь, что padding и border включены в ширину и высоту */
    padding: 10px; /* Пример внутреннего отступа */
    border: 2px solid #f1f1f1; /* Добавляет черный бордер вокруг каждой ячейки */
    border-radius: 10px; /* Округляет углы ячеек */

    &__panel{
        width: 100%;
        display: flex;
        padding: 5px;
        font-weight: bolder;
        color: #dbdbdb;
        font-size: 14px;
    }

    &__panel-title{
        width: 50%;
        display: flex;
        justify-content: left;
    }

    &__panel-buttons{
        width: 50%;
        display: flex;
        justify-content: right;
    }

    &__panel-button{
        margin: 5px;
        color: #dbdbdb;
    }

    &__container{
        flex: 1 1 calc(50% - 10px); /* Занимает половину пространства родителя минус gap */
        /*height: 100px; !* Пример высоты, может быть адаптирована под ваши нужды *!*/
        display: flex;
        justify-content: left;
        align-items: baseline; /* Центрирует текст внутри ячейки */
    }
}

.condition-item-block{
    padding: 0 5px;
}


/* Медиа-запросы для адаптации под мобильные устройства */
@media (max-width: 600px) {
    .cell {
        flex: 1 1 100%; /* На маленьких экранах каждая ячейка занимает всю ширину */
    }
}
</style>
