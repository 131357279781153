import ServiceApi from '../ServiceApi';
/** Сервис для экспорта активных конструкций свободы */
export default class ServiceExportFree {

    /**
     * Название микросервиса для запросов
     * @private
     * @static
     * @type {String}
     */
    static _microserviceName = "catalog";

    static _requestRouts = {
        freeExport: "/catalog/free-export",
        freeExportStatus: "/catalog/free-status"
    };
    /**
     * Поставить задачу на экспорт
     * @static
     * @async
     * @property {Function} actionAfter - событие, сработающее после получения данных
     * @returns {Object}
     */
    static async export(actionAfter = () => {}) {
        try {
            const res = await ServiceApi.get(this._microserviceName, this._requestRouts.freeExport);
            const {data = {}} = res;
            const {data: {task_id: taskId}} = data;
            actionAfter(taskId);
        }
        catch(error) {
            console.log(error);
        }
    }

    /**
     * @param {Number} taskId
     * @param actionAfter
     * @return {Promise<void>}
     */
    static async getStatus(taskId, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.get(this._microserviceName, this._requestRouts.freeExportStatus + "?task_id=" + String(taskId));
            const {data = {}} = res;
            const {data: {message = null, ready = null, body = null}} = data;
            actionAfter(message, ready, body);
        }
        catch(error) {
            console.log(error);
        }
    }
}
