<template>
	<div
        class="condition-item-element"
    >
        <div class="condition-item-element__select">
            <BaseSelect
                class="select"
                defaultTitle="Выберите значение"
                :value="attribute"
                :options="selectItems"
                :position="'left'"
                :isFixed="true"
                @check="onChangeSelect"
            >
                <template #header>
                    <span><b>Выберите значение</b></span>
                </template>
            </BaseSelect>
        </div>
        <div class="condition-item-element__input">
            <BaseInput
                v-if="attribute !== null"
                placeholder="Значение"
                :value="value"
                view="primary"
                @input="onEditInputLazy"
            />
        </div>
	</div>
</template>

<script>
import BaseSelect from "../Base/BaseSelect";
import BaseInput from "../Base/BaseTextInput";
export default {
    name: "ConditionItemElement",
    components: {
        BaseSelect,
        BaseInput
    },

    props: {
        attribute: {
            type: String,
            default: null
        },
        value: {
            type: String,
            default: null
        },
        selectItems: {
            type: Array,
            default: () => ([])
        },
        type: {
            type: String,
            default: null
        }
    },

    data: () => ({
        inputLazyTimerId: null
    }),
    watch: {

    },
    mounted() {

    },
    methods: {
        onChangeSelect(value){
            const {id} = value;
            let newValue = this.attribute === id ? null : id;

            this.$emit("onChangeSelect", {
                type: this.type,
                attributeOld: this.attribute,
                attributeNew: newValue
            });
        },
        /**
         * Событие ленивого ввода
         * @param {String} value - значение инпута
         */
        onEditInputLazy(value = '') {
            if (this.inputLazyTimerId !== null) {
                clearTimeout(this.inputLazyTimerId);
                this.inputLazyTimerId = null;
            }
            this.inputLazyTimerId = setTimeout(() => {
                this.$emit("onEditInput", {
                    type: this.type,
                    attribute: this.attribute,
                    value: value.trim()
                });
            }, 900);
        },
        onEditInput(value){
            this.$emit("onEditInput", {
                type: this.type,
                attribute: this.attribute,
                value: value
            });
        }
    }
}
</script>

<style lang="scss" scoped>

.condition-item-element {
    display: flex;

    &__select{
        display: inline;
        padding: 5px;
    }

    &__input{
        display: inline;
        padding: 5px;
    }
}
</style>
