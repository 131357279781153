import ServiceApi from '../ServiceApi';
/** Сервис для справочника */
export default class ServiceCondition {

    /**
     * Название микросервиса для запросов
     * @private
     * @static
     * @type {String}
     */
    static _microserviceName = "catalog";

    static _requestRouts = {
        conditionList: "/condition/list",
        conditionElementList: "/condition/elements",
        conditionCreate: "/condition/create",
        conditionUpdate: "/condition/update",
        conditionDelete: "/condition/delete",
        conditionCalculate: "/condition/calculate"
    };
    /**
     * Получить список условий
     * @static
     * @async
     * @property {Function} actionAfter - событие, сработающее после получения данных
     * @returns {Object}
     */
    static async getList(actionAfter = () => {}) {
        try {
            const res = await ServiceApi.get(this._microserviceName, this._requestRouts.conditionList);
            const {data = {}} = res;
            const {data: array = []} = data;
            let items = [];
            array.forEach((item) => {
                let {id, data: jsonInfo = {}, count = 0} = item
                let {updates = [], conditions = []} = jsonInfo
                items.push({id, updates, conditions, count})
            });
            actionAfter(items);
        }
        catch(error) {
            console.log(error);
        }
    }

    /**
     *
     * @param actionAfter
     * @return {Promise<void>}
     */
    static async getElements(actionAfter = () => {}) {
        try {
            const res = await ServiceApi.get(this._microserviceName, this._requestRouts.conditionElementList);
            const {data = {}} = res;
            const {data: array = []} = data;
            actionAfter(array);
        }
        catch(error) {
            console.log(error);
        }
    }

    /**
     *
     * @param item
     * @param actionAfter
     * @param actionError
     * @return {Promise<void>}
     */
    static async calculate(item, actionAfter = () => {}, actionError = () => {}) {
        try {
            const res = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.conditionCalculate,
                item
            );
            const {data = {}} = res;
            const {data: obj = {}} = data;
            const {count = "0"} = obj
            actionAfter(count);
        }
        catch(error) {
            const response = this._errorAfter(error)
            this._createErrorAlert(response)
            actionError(response)
        }
    }

    /**
     *
     * @param item
     * @param actionAfter
     * @param actionError
     * @return {Promise<void>}
     */
    static async create(item, actionAfter = () => {}, actionError = () => {}) {
        try {
            const res = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.conditionCreate,
                item
            );
            const {data = {}} = res;
            const {data: newItem = {}} = data;
            let {id, data: jsonInfo = {}, count = 0} = newItem
            let {updates = [], conditions = []} = jsonInfo
            actionAfter({id, updates, conditions, count});
        }
        catch(error) {
            const response = this._errorAfter(error)
            this._createErrorAlert(response)
            actionError()
        }
    }

    /**
     *
     * @param {Number} id
     * @param item
     * @param actionAfter
     * @param actionError
     * @return {Promise<void>}
     */
    static async update(id, item, actionAfter = () => {}, actionError = () => {}) {
        let route = this._requestRouts.conditionUpdate + "/";
        route +=  String(id)
        try {
            const res = await ServiceApi.post(
                this._microserviceName,
                route,
                item
            );
            const {data = {}} = res;
            const {data: newItem = {}} = data;
            let {data: jsonInfo = {}, count = 0} = newItem
            let {updates = [], conditions = []} = jsonInfo
            actionAfter({id, updates, conditions, count});
        }
        catch(error) {
            const response = this._errorAfter(error)
            this._createErrorAlert(response)
            actionError()
        }
    }

    /**
     *
     * @param {Number} id
     * @param actionAfter
     * @param actionError
     * @return {Promise<void>}
     */
    static async delete(id, actionAfter = () => {}, actionError = () => {}) {
        try {
            await ServiceApi.delete(
                this._microserviceName,
                this._requestRouts.conditionDelete + "/" + String(id)
            );
            actionAfter();
        }
        catch(error) {
            const response = this._errorAfter(error)
            this._createErrorAlert(response)
            actionError()
        }
    }

    /**
     * Извлечение ошибок
     * @param error
     * @private
     */
    static _errorAfter(error) {
        const { response = {} } = error;
        const { data: responseData = {} } = response;
        const { data: responseErrors = {} } = responseData;
        return responseErrors;
    }

    /**
     *
     * @param responseErrors
     * @return {boolean}
     * @private
     */
    static _createErrorAlert(responseErrors) {
        let keys = Object.keys(responseErrors)
        if(!keys.length){
            alert("Что то пошло не так")
            return false
        }

        let message = "";
        keys.forEach((key) => {
            let messages = responseErrors[key] || [];
            message += messages.join('\n');
        })
        alert(message);
    }

}
