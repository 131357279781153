const SchemePageHandbook = {
    propertyColumns: {
        side: ['kind_id', 'type_id', 'kind_code_id', 'material_id'],
        marker: ['supplier_id']
    },
    schemeColumns: {
        marker: [
            {
                prop: 'entity_info',
                label: '',
                isSlot: true,
                columnStyles: {
                    width: '100px'
                }
            },
            {
                prop: 'ooh_marker_id',
                label: 'ID',
                canSearch: true,
                canSorted: true
            },
            {
                prop: 'supplier_id',
                label: 'Оператор',
                canSearch: true,
                canSorted: true,
                isSlot: true,
                isModifiered: true,
                changeOptions: [],
                columnStyles: {
                    width: '190px'
                }
            },
            {
                prop: 'country_id',
                label: 'Страна',
                isSlot: true,
                isModifiered: true,
                columnStyles: {
                    width: '120px'
                },
                classes: 'cell-country'
            },
            {
                prop: 'dadata_valid',
                label: 'Найдено DaData',
                // canSearch: true,
                // canSorted: true,
                // isSlot: true,
                // isModifiered: true,
                changeOptions: [],
                columnStyles: {
                    width: '100px',
                }
            },
            {
                prop: 'region_id',
                label: 'Регион',
                // canSearch: true,
                // canSorted: true,
                // isSlot: true,
                // isModifiered: true,
                changeOptions: [],
                columnStyles: {
                    width: '120px',
                }
            },
            {
                prop: 'city_id',
                label: 'Город',
                canSearch: true,
                canSorted: true,
                isSlot: true,
                isModifiered: true,
                changeOptions: [],
                columnStyles: {
                    width: '190px',
                }
            },
            {
                prop: 'location_id',
                label: 'Населенный пункт',
                canSearch: true,
                canSorted: true,
                isSlot: true,
                isModifiered: true,
                changeOptions: [],
                columnStyles: {
                    width: '190px',
                }
            },
            // {
            //     prop: 'type_id',
            //     label: 'Тип',
            //     canSearch: true,
            //     canSorted: true,
            //     changeOptions: [],
            //     columnStyles: {
            //         width: '190px'
            //     }
            // },
            // {
            //     prop: 'kind_id',
            //     label: 'Вид',
            //     canSearch: true,
            //     canSorted: true,
            //     changeOptions: [],
            //     columnStyles: {
            //         width: '190px'
            //     }
            // },
            // {
            //     prop: 'format_id',
            //     label: 'Формат',
            //     canSearch: true,
            //     canSorted: true,
            //     columnStyles: {
            //         width: '100px'
            //     }
            // },
            // {
            //     prop: 'kind_code_id',
            //     label: 'Обозначение',
            //     canSearch: true,
            //     canSorted: true,
            //     changeOptions: [],
            //     columnStyles: {
            //         width: '100px'
            //     }
            // },
            // {
            //     label: 'Материал',
            //     prop: 'material_id',
            //     canSearch: true,
            //     canSorted: true,
            //     isSlot: true,
            //     isModifiered: true,
            //     changeOptions: []
            // },
            {
                prop: 'direction',
                label: 'Азимут',
                canSearch: true,
                canSorted: true,
                isSlot: true,
                isModifiered: true,
                columnStyles: {
                    width: '100px',
                }
            },
            // {
            // 	prop: 'lat',
            // 	label: 'Широта',
            // 	canSearch: true,
            // 	canSorted: true,
            //     isSlot: true,
            //     isModifiered: true,
            // },
            // {
            // 	prop: 'lng',
            // 	label: 'Долгота',
            // 	canSearch: true,
            // 	canSorted: true,
            //     isSlot: true,
            //     isModifiered: true,
            // },
            {
                prop: 'lat',
                label: 'Широта',
                changeOptions: [],
                columnStyles: {
                    width: '100px',
                }
            },
            {
                prop: 'lng',
                label: 'Долгота',
                changeOptions: [],
                columnStyles: {
                    width: '100px',
                }
            },
            {
                prop: 'image_map',
                label: 'Карта',
                isSlot: true,
                columnStyles: {
                    width: '115px'
                }
            },
        ],
        side: [
            {
                prop: 'entity_info',
                label: '',
                isSlot: true,
                columnStyles: {
                    width: '100px'
                }
            },
            {
                prop: 'image_src',
                label: 'Фото',
                isSlot: true,
                columnStyles: {
                    width: '100px'
                }
            },
            {
                prop: 'oohdesk_id',
                label: 'ID',
                isSlot: true,
                canSearch: true,
                canSorted: true
            },
            {
                prop: 'supplier_id',
                label: 'Оператор',
                canSearch: true,
                canSorted: true,
                changeOptions: [],
                columnStyles: {
                    width: '190px'
                }
            },
            {
                prop: 'gid',
                label: 'GID',
                canSearch: true,
                canSorted: true,
                isSlot: true,
                isModifiered: true,
                columnStyles: {
                    width: '190px'
                }
            },
            {
                prop: 'espar_id',
                label: 'ESPAR ID',
                canSearch: true,
                canSorted: true,
                isSlot: true,
                isModifiered: true,
                columnStyles: {
                    width: '190px'
                }
            },
            // {
            //     prop: 'country_id',
            //     label: 'Страна',
            //     columnStyles: {
            //         width: '120px'
            //     },
            //     classes: 'cell-country'
            // },
            // {
            //     prop: 'city_id',
            //     label: 'Город',
            //     canSearch: true,
            //     canSorted: true,
            //     changeOptions: [],
            //     columnStyles: {
            //         width: '190px',
            //     }
            // },
            {
                prop: 'address',
                label: 'Адрес',
                canSearch: true,
                canSorted: true,
                isSlot: true,
                isModifiered: true,
                columnStyles: {
                    width: '400px',
                }
            },
            {
                prop: 'code',
                label: 'Сторона',
                canSearch: true,
                canSorted: true,
                isSlot: true,
                isModifiered: true,
                columnStyles: {
                    textAlign: 'center',
                },
                classes: ['code-cell']
            },
            {
                prop: 'type_id',
                label: 'Тип',
                canSearch: true,
                canSorted: true,
                isSlot: true,
                isModifiered: true,
                changeOptions: [],
                columnStyles: {
                    width: '190px'
                }
            },
            {
                prop: 'kind_id',
                label: 'Вид',
                canSearch: true,
                canSorted: true,
                isSlot: true,
                isModifiered: true,
                changeOptions: [],
                columnStyles: {
                    width: '190px'
                }
            },
            {
                prop: 'format_id',
                label: 'Формат',
                canSearch: true,
                canSorted: true,
                isSlot: true,
                isModifiered: true,
                columnStyles: {
                    width: '100px'
                }
            },
            {
                prop: 'kind_code_id',
                label: 'Обозначение',
                canSearch: true,
                canSorted: true,
                isSlot: true,
                isModifiered: true,
                changeOptions: [],
                columnStyles: {
                    width: '100px'
                }
            },
            {
                label: 'Материал',
                prop: 'materials',
                canSearch: true,
                canSorted: true,
                isSlot: true,
                isModifiered: false,
                changeOptions: []
            },
            {
                prop: 'light',
                label: 'Освещение',
                canSearch: true,
                canSorted: true,
                isSlot: true,
                columnStyles: {
                    width: '120px'
                }
            },
            {
                label: 'Направление',
                prop: 'move_direction',
                canSearch: true,
                canSorted: true,
                isSlot: true,
                isModifiered: true
            },
            // {
            //     prop: 'direction',
            //     label: 'Азимут',
            //     canSearch: true,
            //     canSorted: true,
            //     columnStyles: {
            //         width: '100px',
            //     }
            // },
            // {
            //     label: 'Материал',
            //     prop: 'material_id',
            //     canSearch: true,
            //     canSorted: true,
            //     changeOptions: []
            // },
            {
                prop: 'ots',
                label: 'OTS',
                canSearch: true,
                canSorted: true,
                isSlot: true,
                isModifiered: true,
                columnStyles: {
                    width: '100px',
                }
            },
            {
                prop: 'grp',
                label: 'GRP',
                canSearch: true,
                canSorted: true,
                isSlot: true,
                isModifiered: true,
                columnStyles: {
                    width: '100px',
                }
            },
            {
                prop: 'video_time',
                label: 'Время видео',
                canSearch: true,
                canSorted: true,
                isSlot: true,
                isModifiered: true,
            },
            {
                prop: 'video_frequency',
                label: 'Частота видео',
                canSearch: true,
                canSorted: true,
                isSlot: true,
                isModifiered: true,
            },
            {
                prop: 'video_block',
                label: 'Видеоблок',
                canSearch: true,
                canSorted: true,
                isSlot: true,
                isModifiered: true,
            },
            {
                prop: 'video_block_clients',
                label: 'Клиенты видеоблока',
                canSearch: true,
                canSorted: true,
                isSlot: true,
                isModifiered: true,
            },
            {
                prop: 'print_price',
                label: 'Печать',
                canSearch: true,
                canSorted: true,
                isSlot: true,
                isModifiered: true,
            },
            {
                prop: 'install_price',
                label: 'Монтаж',
                canSearch: true,
                canSorted: true,
                isSlot: true,
                isModifiered: true,
            },
            {
                prop: 'additional_install_price',
                label: 'Доп. Монтаж',
                canSearch: true,
                canSorted: true,
                isSlot: true,
                isModifiered: true,
            },
            // {
            // 	prop: 'lat',
            // 	label: 'Широта',
            // 	canSearch: true,
            // 	canSorted: true,
            //     isSlot: true,
            //     isModifiered: true,
            // },
            // {
            // 	prop: 'lng',
            // 	label: 'Долгота',
            // 	canSearch: true,
            // 	canSorted: true,
            //     isSlot: true,
            //     isModifiered: true,
            // },
            // {
            //     prop: 'image_map',
            //     label: 'Координаты',
            //     isSlot: true,
            //     columnStyles: {
            //         width: '115px'
            //     }
            // },
            {
                prop: 'image_link',
                label: 'Ссылка',
                canSearch: true,
                isSlot: true,
            },
            {
                prop: 'createdDate',
                label: 'Дата создания',
                isSlot: false,
                canSearch: false,
                canSorted: false,
                columnStyles: {
                    width: '100px',
                }
            },
            {
                prop: 'updatedDate',
                label: 'Дата изменения',
                isSlot: false,
                canSearch: false,
                canSorted: false,
                columnStyles: {
                    width: '100px',
                }
            }
        ]
    },
    handbookApi: {
        side: {
            modifyList: "/catalog/modify-list-side",
            cartList: "/catalog/modify-cart-side",
            sideList: "/catalog/total-list"
        },
        marker: {
            modifyList: "/catalog/modify-list-marker",
            cartList: "/catalog/modify-cart-marker",
            sideList: "/catalog/list-marker"
        }
    },
    allTabTitles: {
        side: 'Все стороны',
        marker: 'Все конструкции'
    },
    schemeInfoColumns: {
        marker: [
            {
                prop: 'image_src',
                label: 'Фото',
                isSlot: true,
                columnStyles: {
                    width: '100px'
                }
            },
            {
                prop: 'oohdesk_id',
                label: 'ID',
            },
            {
                prop: 'gid',
                label: 'GID',
            },
            {
                prop: 'espar_id',
                label: 'ESPAR ID',
                columnStyles: {
                    width: '100px'
                }
            },
            {
                prop: 'address',
                label: 'Адрес',
                columnStyles: {
                    width: '400px',
                }
            },
            {
                prop: 'code',
                label: 'Сторона',
                columnStyles: {
                    textAlign: 'center',
                    width: '65px',
                },
                classes: ['code-cell']
            },
            {
                prop: 'type_id',
                label: 'Тип',
                changeOptions: [],
                columnStyles: {
                    width: '100px'
                }
            },
            {
                prop: 'kind_id',
                label: 'Вид',
                changeOptions: [],
                columnStyles: {
                    width: '100px'
                }
            },
            {
                prop: 'format_id',
                label: 'Формат',
                columnStyles: {
                    width: '70px'
                }
            },
            {
                prop: 'kind_code_id',
                label: 'Обозначение',
                changeOptions: [],
                columnStyles: {
                    width: '100px'
                }
            },
            {
                label: 'Материал',
                prop: 'materials',
                isSlot: true,
                changeOptions: [],
                columnStyles: {
                    width: '100px'
                }
            },
            {
                prop: 'light',
                label: 'Освещение',
                columnStyles: {
                    width: '120px'
                }
            },
            {
                label: 'Направление',
                prop: 'move_direction',
            },
            {
                prop: 'ots',
                label: 'OTS',
                columnStyles: {
                    width: '100px',
                }
            },
            {
                prop: 'grp',
                label: 'GRP',
                columnStyles: {
                    width: '100px',
                }
            },
            {
                prop: 'video_time',
                label: 'Время видео',
            },
            {
                prop: 'video_frequency',
                label: 'Частота видео',
            },
            {
                prop: 'video_block',
                label: 'Видеоблок',
            },
            {
                prop: 'video_block_clients',
                label: 'Клиенты видеоблока',
            },
            {
                prop: 'image_link',
                label: 'Ссылка',
            },
        ],
        side: [
            {
                prop: 'ooh_marker_id',
                label: 'ID',
            },
            {
                prop: 'supplier_id',
                label: 'Оператор',
            },
            {
                prop: 'dadata_valid',
                label: 'Найдено DaData',
            },
            {
                prop: 'region_id',
                label: 'Регион',
            },
            {
                prop: 'city_id',
                label: 'Город',
            },
            {
                prop: 'location_id',
                label: 'Населенный пункт',
            },
            {
                prop: 'direction',
                label: 'Азимут',
                isSlot: true
            },
            {
                prop: 'lat',
                label: 'Широта'
            },
            {
                prop: 'lng',
                label: 'Долгота'
            },
            {
                prop: 'image_map',
                label: 'Карта',
                isSlot: true
            },
        ]
    }
};
export default SchemePageHandbook;
