<template>
	<div class="map-image">
		<img
			:src="imgPath"
			class="map-image__min-image"
			@click="onOpen"
		>
		<AppPopup
			v-show="isPopupOpen"
            :innerStyle="{width: '70%', height: '80%'}"
			@close="onClose"
		>
<!--			<div class="photo-popup">
				<img :src="imgPath" class="photo-popup__image">
			</div>-->
            <map-wrapper
                :driver="'google'"
                :renderDomElementId="'map-single-wrapper'"
                :mapParams="mapParams"
                :markers="this.markers"
            />
		</AppPopup>
	</div>
</template>

<script>
import AppPopup from '@/components/Popup'
import MapWrapper from '@/components/MapV2/components/MapWrapper';

export default {
	name: "MapImage",
	props: {
		lat: {
			type: [String, Number],
			required: true,
		},
		lng: {
			type: [String, Number],
			required: true,
		},
	},
	components: {
		AppPopup,
        MapWrapper
	},
	data() {
		return {
			isPopupOpen: false,
            mapParams: {
                center: {lat: 55.755826, lng: 34.617300},
                zoom: 17,
            },
            markers: [],
		}
	},
    beforeMount() {
        this.mapParams.center.lat = Number(this.lat);
        this.mapParams.center.lng = Number(this.lng);
        this.markers.push({
            id: "777",
            lat: Number(this.lat),
            lng: Number(this.lng),
            fill_color: "0000ff"
        });
    },
	computed: {
		imgPath () {
            return `https://static-maps.yandex.ru/1.x/?ll=${this.lng},${this.lat}&pt=${this.lng},${this.lat},org&size=500,450&z=16&l=map`
        },
	},
	methods: {
		onOpen() {
			this.isPopupOpen = true
		},
		onClose() {
			this.isPopupOpen = false
		}
	}
}
</script>

<style lang="scss" scoped>
.map-image {
	width: 56px;
	height: 56px;
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;

	&__min-image {
		width: 230px;
		display: block;
		cursor: pointer;
	}

	.photo-popup {
		width: 500px;
		height: 450px;
		overflow: hidden;

		&__image {
			width: 100%;
			display: block;
		}
	}

    .popup-inner{
        width: 70%;
        height: 80%;
    }
}
</style>
