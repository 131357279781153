<template>
	<div
        class="handbook-matching"
    >
        <div
            v-if="message"
        >
            {{message}}
        </div>
        <match-file-attaching
            v-if="taskId === null"
            @attach="onFileAttach"
        />
	</div>
</template>

<script>

import MatchFileAttaching from "@/components/Matching/MatchFileAttaching";

import ServiceHandbook from "@/services/ServicesHandbook/ServiceHandbook";
export default {
    name: "HanbookMatching",
    components: {
        MatchFileAttaching
    },

    props: {
        isMatchingOpened: {
            type: Boolean,
            default: false
        },
    },

    data: () => ({
        isStepLoading: false,
        taskId: null,
        timerId: null,
        message: ''
    }),
    watch: {
        isMatchingOpened(){
            if(!this.isMatchingOpened){
                this.onClose()
            }
        }
    },
    mounted() {

    },
    methods: {
        async checkTask(){
            this.timerId = setInterval(async () => {
                ServiceHandbook.checkTask(this.taskId, (data) => {
                    this.message = data.message;
                    if(data.ready){
                        clearInterval(this.timerId);
                        this.timerId = null;
                        window.open(data.link);
                        this.onClose();
                    }
                }, () => {
                    this.onError('Не удалось дополнить файл');
                });
            }, 30000);
        },
        /**
         * Событие прикрепления файла
         * @param {Object} file - файл
         * @param {String} attach
         */
        onFileAttach(file, attach) {
            this.uploadFile(file, attach);
        },
        /**
         * Загрузка файла
         * @param {Object} file - файл
         * @param {String} matchType
         */
        uploadFile(file, matchType) {
            ServiceHandbook.matchFile(file, matchType, (data) => {
                let {task_id:taskId = null, message = ''} = data;
                this.taskId = taskId;
                if(this.taskId !== null){
                    this.message = "Ожидает выполнения";
                    this.checkTask();
                }else {
                    this.message = message;
                }
            }, () => {
                this.onError('Не удалось загрузить файл');
            });
        },
        onClose(){
            this.message = '';
            this.taskId = null;
            if(this.timerId !== null){
                clearInterval(this.timerId);
                this.timerId = null;
            }
            this.$emit('onCloseMatching')
        },
    }
}
</script>

<style lang="scss" scoped>

.handbook-matching {
    padding: 20px;

    &__button{
        padding: 10px;
    }


}
</style>
