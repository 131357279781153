<template>
	<div class="handbook" ref="handbook">
        <div class="handbook-panel">
            <base-button
                class="handbook-panel__button"
                :view="getView('marker')"
                @click="setCurrentEntity('marker')"
            >
                Конструкции
            </base-button>

            <base-button
                class="handbook-panel__button"
                :view="getView('side')"
                @click="setCurrentEntity('side')"
            >
                Стороны
            </base-button>
        </div>
		<handbook-entity
            v-if="usersData.length"
            :currentEntity="currentEntity"
            :usersData="usersData"
            @onExportFreeSides="onExportFreeSides"
        ></handbook-entity>
	</div>
</template>

<script>
import HandbookEntity from "@/components/Handbook/HandbookEntity";
import BaseButton from "@/components/Base/BaseButton";
import ServiceExportFree from "../services/ServicesExportFree/ServiceExportFree";
import {utilsBase64} from "../utils/utilsBase64"

export default {
	name: "HandbookPage",
	components: {
        HandbookEntity,
        BaseButton
	},
	data() {
		return {
            currentEntity: 'marker',
            usersData: [],
            taskId: null,
            taskTimerId : null,
            taskMessage: null
		}
	},
	computed: {

	},
    beforeMount() {
        this.fetchUsers()
    },
	methods: {
        getView(entity){
            if(entity === this.currentEntity){
                return 'primary';
            }else {
                return 'secondary';
            }
        },
        setCurrentEntity(entity){
            this.currentEntity = entity;
        },
        /**
         */
        fetchUsers() {
            this.$axios.post('https://map.oohdesk.ru/v2/user-info')
                .then(res => {
                    this.usersData = res.data.data;
                })
                .catch(err => { console.log(err) })
        },
        onExportFreeSides(){
            if(this.taskId !== null){
                return;
            }
            alert("Задача на экспорт создана, подождите 5-10 минут и не перезагружайте страницу");
            ServiceExportFree.export((taskId) => {
                this.taskId = taskId
                this.checkTask()
            })
        },
        checkTask(){
            this.taskTimerId = setInterval(async () => {
                ServiceExportFree.getStatus(this.taskId, (message, ready, body) => {
                    if(message !== this.taskMessage){
                        alert(message);
                        this.taskMessage = message;
                    }
                    if(ready){
                        clearInterval(this.taskTimerId);
                        this.taskTimerId = null;
                        this.taskId = null;
                        this.taskMessage = null;
                    }
                    if(body !== null){
                        let blobCsv = utilsBase64.convertToBlob(body, "text/csv")
                        window.open(window.URL.createObjectURL(blobCsv), "_blank");
                    }
                });
            }, 30000);
        }
	}
}
</script>

<style lang="scss" scoped>
.handbook {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	position: relative;
    margin-top: -20px;

    .handbook-panel{
        display: inherit;
        padding-bottom: 20px;

        &__button {
            margin: 8px 10px;
            &_right {
                margin-left: auto;
            }

            .selected{
                background: #4A92F6;
            }
        }
    }

}
</style>
