export class utilsBase64 {
	/**
	 * Преобразование base64 в Blob
	 * @param {String} base64String
	 * @param {String} type
	 * @return {Blob}
	 */
	static convertToBlob = (base64String, type) => {
		const sliceSize = 512;
		const byteCharacters = atob(base64String);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);

			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		return new Blob(byteArrays, {type: type});
	};
}