<template>
    <app-login-v2 @onLogin="onLogin"/>
</template>

<script>
import AppLoginV2 from "@/layouts/components/LoginV2";
import ServiceAuth from "../services/ServicesAuth/ServiceAuth";
export default {
    name: "LayoutLoginV2",
    components: {
        AppLoginV2,
    },
    methods: {
        async onLogin(login = '', password = '') {
            await ServiceAuth.login(login, password, () => {
                this.$router.push({name: 'handbook'});
            });
        }
    }
}
</script>

<style scoped>

</style>
